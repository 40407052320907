<template>
	<div>
		<v-btn
			:loading="pageLoading || uploading"
			class="white--text avatar-change-btn"
			color="blue darken-4"
			icon
			large
			v-on:click="changeImage()"
			v-if="canChange"
			:disabled="pageLoading || uploading"
		>
			<v-icon v-if="value" large>mdi-camera-retake</v-icon>
			<v-icon v-else large>mdi-camera-plus</v-icon>
		</v-btn>
		<v-file-input
			:disabled="uploading"
			v-on:change="updateFile($event)"
			class="d-none"
			ref="avatar-input"
			accept="image/png, image/jpeg"
		></v-file-input>
		<v-avatar size="250">
			<v-img height="100%" :src="getImagePath()" :lazy-src="getImagePath()"> </v-img>
		</v-avatar>
	</div>
</template>

<script>
import { head } from "lodash";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "v-avatar-update",
	props: {
		pageLoading: {
			type: Boolean,
			default: false,
		},
		canChange: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			profile: null,
			uploading: false,
		};
	},
	methods: {
		getImagePath() {
			if (this.value) {
				return this.value;
			}
			return process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
		},
		changeImage() {
			const avatar_ref = this.$refs["avatar-input"];
			if (avatar_ref) {
				avatar_ref.$refs["input"].click();
			}
		},
		updateFile(file) {
			this.uploading = true;
			this.$emit("loading", true);
			const formData = new FormData();
			formData.append("files[0][file]", file);
			formData.append("files[0][name]", file.name.split(".").slice(0, -1).join("."));
			formData.append("files[0][suffix]", `.${file.name.split(".").pop()}`);
			UploadFiles(formData)
				.then((response) => {
					const profile = head(response);
					if (profile.url) {
						this.$emit("input", profile.url);
					}
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.uploading = false;
					this.$emit("loading", false);
				});
		},
	},
};
</script>
